import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  // baseURL: "http://localhost:3001/api",
  baseURL: process.env.VUE_APP_BASE_API || "http://localhost:3001/manager/api", // api 的 base_url
  timeout: 60000 // request timeout
});

// console.log("api", process.env.API_SERVER);

export function getExtraHeaders() {
  // 让每个请求携带token-- ['ADMINTOKEN']为自定义key 请根据实际情况自行修改
  return { ADMINTOKEN: getToken() };
}

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (store.getters.token) {
      Object.assign(config.headers, getExtraHeaders());
    }
    return config;
  },
  error => {
    // Do something with request error
    // console.log(error); // for debug
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.errno === 10001) {
      MessageBox.alert("系统未登录，请重新登录", "错误", {
        confirmButtonText: "确定",
        type: "error"
      }).then(() => {
        store.dispatch("FedLogOut").then(() => {
          location.reload();
        });
      });
      return Promise.reject(response);
    }
    if (res.errno !== 200) {
      Message({
        message: res.errmsg + " 异常代码：" + res.errno,
        type: "error",
        duration: 5 * 1000
      });
      return Promise.reject(response);
    } else {
      return response;
    }
  },
  error => {
    Message({
      message: error + "（请联系系统管理员）",
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;

export function downloadFile(url, filename) {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.setAttribute("target", "_blank");
  a.click();
}

export function downloadFile2(url, filename) {
  const req = axios.create();
  req({
    method: "get",
    url,
    responseType: "blob"
  }).then(response => {
    const href = URL.createObjectURL(response.data); //根据二进制对象创造新的链接
    const a = document.createElement("a");
    a.setAttribute("href", href);
    a.setAttribute("download", filename);
    a.click();
    URL.revokeObjectURL(href);
  });
}
